import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarHomeLgComponent } from './components/toolbar-home-lg/toolbar-home-lg.component';
import { FooterHomeLgComponent } from './components/footer-home-lg/footer-home-lg.component';
import { PopupComponent } from './components/popup/popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PopupDatapickerComponent } from './components/popup-datapicker/popup-datapicker.component';
import { PopupAddressComponent } from './components/popup-address/popup-address.component';
import { PopupGeophoneComponent } from './components/popup-geophone/popup-geophone.component';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarHomeLgComponent,
    FooterHomeLgComponent,
    PopupComponent,
    PopupDatapickerComponent,
    PopupAddressComponent,
    PopupGeophoneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
