import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/home-lg/home-lg.module').then(m => m.HomeLgModule)
  },
  {
    path: 'reservar-cita',
    loadChildren: () => import('./views/reservar-cita/reservar-cita.module').then(m => m.ReservarCitaModule)
  },
  {
    path: 'area-pacientes',
    loadChildren: () => import('./views/area-pacientes/area-pacientes.module').then(m => m.AreaPacientesModule)
  }
];

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: 'disabled'
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
