import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { lista } from 'src/app/interfaces/lista-paises'
import { venezuela } from 'src/app/interfaces/venezuela'

@Component({
  selector: 'app-popup-address',
  templateUrl: './popup-address.component.html',
  styleUrls: ['./popup-address.component.css']
})
export class PopupAddressComponent implements OnInit, AfterViewInit {

  flatCountry:boolean = true;
  flatVzla:boolean = false;
  paises:Array<{name:string,svg:SafeHtml}> = [];
  search:FormControl = new FormControl('');
  estados:Array<string> = [];
  ciudades:Array<string> = [];
  sector:FormControl = new FormControl('', [Validators.required]);
  myModal:any;
  formLocation:FormGroup = new FormGroup({
    pais: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    ciudad: new FormControl('', [Validators.required])
  });

  @ViewChild('modalLocation') element:ElementRef;
  @Output() 
  closed = new EventEmitter();
  location = new EventEmitter();

  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.cargarPaises();
    this.search.valueChanges.subscribe((element)=>{
      this.cargarPaises(element);
    });
    this.formLocation.get('pais').valueChanges.subscribe(()=>{
      this.cargarEstados();
    });
    this.formLocation.get('estado').valueChanges.subscribe(()=>{
      this.cargarCiudades();
    });
  }
  ngAfterViewInit(): void {
    this.myModal = new bootstrap.Modal(this.element.nativeElement);
    this.myModal.show();
  }
  modalClose(){
    if (this.sector.valid && this.pais == 'Venezuela' && this.formLocation.valid) {
      this.location.next(`${this.pais}, ${this.estado}, ${this.ciudad}, ${this.sector.value}`);
    } else if (this.formLocation.get('pais').valid && this.pais !== 'Venezuela' && this.sector.valid) {
      this.location.next(`${this.pais}, ${this.sector.value}`);
    }
    this.myModal.hide();
    this.closed.next();
  }

  cargarPaises(element?:string){
    if (typeof element == 'undefined'){
      lista.forEach((item)=>{
        this.paises.push({
          name: item.name,
          svg: this.sanitizer.bypassSecurityTrustHtml(item.svg)
        })
      }); 
    } else if (typeof element !== 'undefined'){
      this.paises = [];
      lista.forEach((item)=>{
        if (item.name.toLocaleLowerCase().includes(element.toLocaleLowerCase())) {
          this.paises.push({
            name: item.name,
            svg: this.sanitizer.bypassSecurityTrustHtml(item.svg)
          });
        }
      });
    }
  }
  cargarEstados(){
    if (this.pais == 'Venezuela') {
      this.flatVzla = true;
      venezuela.forEach((item) => {
        this.estados.push(item.estado);
      });
    } else {
      this.flatVzla = false;
    }
  }
  cargarCiudades(){
    if (this.pais == 'Venezuela') {
      venezuela.forEach((item) => {
        this.estado == item.estado ? this.ciudades=item.ciudades : '';
      });
    }
  }

  get pais() { return this.formLocation.get('pais').value }
  setpais(element:string) {
    this.formLocation.get('pais').setValue(element);
    this.flatCountry = false;
  }
  get estado() { return this.formLocation.get('estado').value }
  get ciudad() { return this.formLocation.get('ciudad').value }
}
