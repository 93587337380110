import {
    trigger,
    transition,
    style,
    query,
    group,
    animateChild,
    animate,
    keyframes,
    state,
  } from '@angular/animations';

  export const efectoOne = 
  trigger('myInsertRemoveTrigger',[
    transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 })),
    ])
  ]);

  export const slideInAnimation =
  trigger('triggerAnimarEnrutamiento', [
    transition('HomeView => TestView', [
        style({ opacity: 0 }),
        animate('4s 100ms ease-out', style({opacity:1}))
    ]),
  ]);