import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

@Component({
  selector: 'app-popup-datapicker',
  templateUrl: './popup-datapicker.component.html',
  styleUrls: ['./popup-datapicker.component.css']
})
export class PopupDatapickerComponent implements OnInit, AfterViewInit {

  itemsYear:Array<string>;
  itemsMonth:Array<{label:string,value:string}>;
  itemsDay:Array<string>;
  myModal:any;
  formDatePicker:FormGroup = new FormGroup({
    year: new FormControl({value: '', disabled: false}, [Validators.required]),
    month: new FormControl({value: '', disabled: true}, [Validators.required]),
    day: new FormControl({value: '', disabled: true}, [Validators.required])
  });

  @ViewChild('modalDatePicker') element:ElementRef;
  @Output() 
  closed = new EventEmitter();
  fecha = new EventEmitter();
  
  constructor() { }

  ngOnInit(){
    this.itemsYear = this.cargaritemsYear();
    this.itemsMonth = this.cargaritemsMonth();
    this.formDatePicker.get('year').valueChanges.subscribe(()=>{
      this.formDatePicker.get('month').enable();
    });
    this.formDatePicker.get('month').valueChanges.subscribe(()=>{
      this.itemsDay = this.cargaritemsDay();
      this.formDatePicker.get('day').enable();
    })
  }
  ngAfterViewInit(): void {
    this.myModal = new bootstrap.Modal(this.element.nativeElement);
    this.myModal.show();
  }

  modalClose(){
    this.formDatePicker.valid ? this.fecha.next(`${this.year}-${this.month}-${this.day}`) : '';
    this.myModal.hide();
    this.closed.next();
  }

  cargaritemsYear():Array<string>{
    let array_year = [];
    for (let index = 1920; index <= new Date().getFullYear(); index++) {
      array_year.push(`${index}`);
    }
    return array_year;
  }
  cargaritemsMonth():Array<{label:string,value:string}>{
    return [
      {value:'01',label:'Enero'},
      {value:'02',label:'Febrero'},
      {value:'03',label:'Marzo'},
      {value:'04',label:'Abril'},
      {value:'05',label:'Mayo'},
      {value:'06',label:'Junio'},
      {value:'07',label:'Julio'},
      {value:'08',label:'Agosto'},
      {value:'09',label:'Septiembre'},
      {value:'10',label:'Octubre'},
      {value:'11',label:'Noviembre'},
      {value:'12',label:'Diciembre'}
    ];
  }
  cargaritemsDay():Array<string>{
    let array_day = [];
    let diasDELmes = new Date(`${this.year}-${(Number.parseInt(this.month)+1).toLocaleString('es', {minimumIntegerDigits:2})}-01`).getDate();
    for (let index = 1; index <= diasDELmes; index++) {
      array_day.push(`${index}`);
    }
    return array_day;
  }

  get year() { return this.formDatePicker.get('year').value }
  get month() { return this.formDatePicker.get('month').value }
  get day() { return this.formDatePicker.get('day').value }
}
