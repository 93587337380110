<footer class="h-100">
    <div class="container-fluid">
      <div class="row py-3">
        <div class="col-md-9">
          <div class="text-danger text-center h5 m-0">Únete a nuestra ATPE-COMUNIDAD en...</div>
        </div>
        <div class="col-md-3 d-flex align-items-md-center justify-content-center">
          <div class="me-3">
            <a class="text-light" href="https://t.me/COMUNIDADENDOCRINA" target="_blank" rel="noopener noreferrer">
              <img width="20" height="20" src="https://atpendocrina.com/assets/icon-telegram.png">
            </a>
          </div>
          <div class="me-3">
            <a class="text-light" href="https://www.instagram.com/notiendocrino/" target="_blank" rel="noopener noreferrer">
              <img width="20" height="20" src="https://atpendocrina.com/assets/icon-instagram.png">
            </a>
          </div>
        </div>
      </div>
      <div class="row bg-danger">
        <div class="text-white text-center text-100 py-2">
          Dra. Neveska C. Higuera V. ©  2020 ATP.
        </div>
      </div>
    </div>
</footer>