import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { efectoOne, slideInAnimation } from './animaciones/animacion';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[
    efectoOne,
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'atpe-web';
  flagWelcom:boolean = true;

  constructor(){
    setTimeout(() => {
      this.flagWelcom = false;
    }, 5100);
  }

  recibirCambiosOutlet(outlet: RouterOutlet){    
    return outlet?.activatedRouteData?.stateAnimation;
  }
}
