<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img src="https://atpendocrina.com/assets/logo.svg" height="50px" alt="ATP Logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">ATPEndocrina</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <!-- ENLACES HOME -->
                    <li #liOne class="nav-item dropdown">
                        <a [routerLink]="['']" routerLinkActive="border-danger border-bottom border-top border-2"
                            [routerLinkActiveOptions]="{exact:true}"
                            (mouseout)="outItem(liOne); subMenuOne.classList.remove('show')"
                            (mouseover)="hoverItem(liOne); subMenuOne.classList.add('show')"
                            class="nav-link dropdown-toggle text-center m-0 h-100 h4" role="button"
                            aria-expanded="false" data-bs-toggle="dropdown" style="cursor: pointer;"
                            data-bs-dismiss="offcanvas"
                            (click)="subMenuOne.classList.contains('show')?subMenuOne.classList.remove('show'):subMenuOne.classList.add('show')">Inicio</a>
                        <ul #subMenuOne class="dropdown-menu dropdown-menu-end"
                            (mouseover)="subMenuOne.classList.add('show')"
                            (mouseout)="subMenuOne.classList.remove('show')">
                            <li><a (click)="subMenuOne.classList.contains('show')?subMenuOne.classList.remove('show'):subMenuOne.classList.add('show')"
                                [routerLink]="['']" fragment="section-one"
                                class="dropdown-item" style="cursor: pointer;">Inicio</a></li>
                            <li><a (click)="subMenuOne.classList.contains('show')?subMenuOne.classList.remove('show'):subMenuOne.classList.add('show')"
                                [routerLink]="['']" fragment="section-two"
                                class="dropdown-item" style="cursor: pointer;">Conóceme</a></li>
                            <li><a  (click)="subMenuOne.classList.contains('show')?subMenuOne.classList.remove('show'):subMenuOne.classList.add('show')"
                                [routerLink]="['']" fragment="section-three"
                                class="dropdown-item" style="cursor: pointer;">Test de atención primaria</a></li>
                        </ul>
                    </li>
                    <!-- ENLACES DE CITAS -->
                    <li #liTwo class="nav-item dropdown">
                        <a [routerLink]="['reservar-cita']"
                            routerLinkActive="border-danger border-bottom border-top border-2"
                            (click)="subMenuTwo.classList.contains('show')?subMenuTwo.classList.remove('show'):subMenuTwo.classList.add('show')"
                            (mouseout)="outItem(liTwo); subMenuTwo.classList.remove('show')"
                            (mouseover)="hoverItem(liTwo); subMenuTwo.classList.add('show')"
                            class="nav-link dropdown-toggle text-center m-0 h-100 h4" style="cursor: pointer;"
                            data-bs-dismiss="offcanvas">Reservar
                            cita</a>
                        <ul #subMenuTwo class="dropdown-menu dropdown-menu-end"
                            (mouseover)="subMenuTwo.classList.add('show')"
                            (mouseout)="subMenuTwo.classList.remove('show')">
                            <li><a (click)="subMenuTwo.classList.contains('show')?subMenuTwo.classList.remove('show'):subMenuTwo.classList.add('show')"
                                [routerLink]="['reservar-cita']" [queryParams]="{modality:'presencial'}"
                                class="dropdown-item" style="cursor: pointer;">Cita presencial</a></li>
                            <li><a (click)="subMenuTwo.classList.contains('show')?subMenuTwo.classList.remove('show'):subMenuTwo.classList.add('show')"
                                [routerLink]="['reservar-cita']" [queryParams]="{modality:'online'}"
                                class="dropdown-item" style="cursor: pointer;">Cita online</a></li>
                        </ul>
                    </li>
                    <!-- ENLACES AREA PACIENTES -->
                    <li #liThree class="nav-item dropdown">
                        <a [routerLink]="['area-pacientes']"
                            routerLinkActive="border-danger border-bottom border-top border-2"
                            (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                            (mouseout)="outItem(liThree); subMenuThree.classList.remove('show')"
                            (mouseover)="hoverItem(liThree); subMenuThree.classList.add('show')"
                            class="nav-link dropdown-toggle text-center m-0 h-100 h4" style="cursor: pointer;"
                            data-bs-dismiss="offcanvas">Área
                            pacientes</a>
                        <ul #subMenuThree class="dropdown-menu dropdown-menu-end"
                            (mouseover)="subMenuThree.classList.add('show')"
                            (mouseout)="subMenuThree.classList.remove('show')">
                            <li>
                                <a  (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                                    [routerLink]="['area-pacientes/reportar-pago']"
                                    class="dropdown-item" style="cursor: pointer;">
                                    Métodos de pago
                                </a>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                                <a  (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                                    [routerLink]="['area-pacientes/cuestionario-antecedentes']"
                                    class="dropdown-item" style="cursor: pointer;">
                                    Cuestionario de antecedentes
                                </a>
                            </li>
                            <li>
                                <a  (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                                    [routerLink]="['area-pacientes/recipes-indicaciones']"
                                    class="dropdown-item" style="cursor: pointer;">
                                    Récipes e indicaciones
                                </a>
                            </li>
                            <li>
                                <a  (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                                    [routerLink]="['area-pacientes/examenes']"
                                    class="dropdown-item" style="cursor: pointer;">
                                    Exámenes médicos
                                </a>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                                <a  (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                                    [routerLink]="['area-pacientes/test-obesidad']"
                                    class="dropdown-item" style="cursor: pointer;">
                                    Test de obesidad
                                </a>
                            </li>
                            <li>
                                <a  (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                                    [routerLink]="['area-pacientes/test-diabetes']"
                                    class="dropdown-item" style="cursor: pointer;">
                                    Test de diabetes
                                </a>
                            </li>
                            <li>
                                <a  (click)="subMenuThree.classList.contains('show')?subMenuThree.classList.remove('show'):subMenuThree.classList.add('show')"
                                    [routerLink]="['area-pacientes/test-tiroides']"
                                    class="dropdown-item" style="cursor: pointer;">
                                    Test de tiroides
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<!--
<nav class="navbar navbar-expand-md navbar-light p-0">
    <div class="container-fluid">
        <a class="navbar-brand ms-5" style="cursor: pointer;">
            <img src="../../../assets/logo.svg" height="50px" alt="ATP Logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel">ATPEndocrina</h5>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li #liOne class="nav-item dropdown">
                    <a [routerLink]="['']"
                        routerLinkActive="border-danger border-bottom border-top border-2"
                        [routerLinkActiveOptions]="{exact:true}"
                        (mouseout)="outItem(liOne)"
                        (mouseover)="hoverItem(liOne)"
                        class="nav-link dropdown-toggle text-center m-0 h-100 h4"
                        role="button" aria-expanded="false"
                        data-bs-toggle="dropdown"
                        style="cursor: pointer;"
                        >
                        Inicio
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item">Inicio</a></li>
                        <li><a class="dropdown-item">Conóceme</a></li>
                        <li><a class="dropdown-item">Test de atención primaria</a></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><a class="dropdown-item">Separated link</a></li>
                    </ul>
                </li>
                <li #liTwo class="nav-item">
                    <a [routerLink]="['/reservar-cita']" routerLinkActive="border-danger border-bottom border-top border-2"  (mouseout)="outItem(liTwo)" (mouseover)="hoverItem(liTwo)" class="nav-link text-center m-0 h-100 h4" style="cursor: pointer;">Reservar cita</a>
                </li>
                <li #liThree class="nav-item">
                    <a routerLinkActive="border-danger border-bottom border-top border-2" (mouseout)="outItem(liThree)" (mouseover)="hoverItem(liThree)" class="nav-link text-center m-0 h-100 h4" style="cursor: pointer;">Conóceme</a>
                </li>
                <li class="nav-item dropdown">
                    <a id="navbarDropdown" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Dropdown</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item" href="#">Separated link</a></li>
                    </ul>
                  </li>
              </ul>
            </div>
        </div>
    </div>
</nav>

<!--

<div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                    <a class="nav-link btn text-white" (click)="toggle()">INICIO</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link btn text-white" (click)="toggle()">RESERVAR CITA</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link btn text-white" (click)="toggle()">CONÓCEME</a>
                </li>
            </ul>
        </div>

-->