import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ConfigModal } from 'src/app/interfaces/objetos';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
  animations: [
    trigger('state', [
      state('opened', style({opacity: 50})),
      state('void, closed', style({opacity: 0})),
      transition('* => *', animate('100ms ease-in')),
    ])
  ]
})
export class PopupComponent implements OnInit, AfterViewInit {

  @ViewChild('divModalTitle') divModalTitle:ElementRef;
  @ViewChild('divModalBody') divModalBody:ElementRef;
  @ViewChild('myModal') element:ElementRef;
  myModal:any;

  @Input()
  configModal: ConfigModal;

  @Output()
  closed = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {    
    this.myModal = new bootstrap.Modal(this.element.nativeElement);
    this.divModalTitle.nativeElement.innerHTML = `<div class="${this.configModal.classTitle}">${this.configModal.title}</div>`;
    this.divModalBody.nativeElement.innerHTML = this.construirMessage();
    this.modalOpen();
  }
  construirMessage():string{

    let messageOriginal;
    for (let index = 0; index < this.configModal.classMessage.length; index++) {
      const element = this.configModal.classMessage[index];
      messageOriginal = this.configModal.message.replace(`?${index}`,`class="${element}"`);
    }
    
    return messageOriginal;
  }

  modalOpen(){
    this.myModal.show();
  }
  modalClose(){
    this.myModal.hide();
    this.closed.next();
  }
  modalToggle(){
    this.myModal.toggle();
  }

}
