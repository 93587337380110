<!-- Modal De Fechas -->
<div #modalDatePicker class="modal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
            Fecha
        </div>
        <div class="modal-body">
            <form [formGroup]="formDatePicker" class="row g-3">
                <div class="col-md-4">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text" id="basic-addon-year">
                            Año
                        </span>
                        <select formControlName="year"
                            class="form-select form-select-sm"
                            aria-describedby="basic-addon-year">
                            <option *ngFor="let itemYear of itemsYear" [value]="itemYear">
                                {{itemYear}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text" id="basic-addon-month">
                            Mes
                        </span>
                        <select formControlName="month"
                            class="form-select form-select-sm"
                            aria-describedby="basic-addon-month">
                            <option *ngFor="let itemMonth of itemsMonth" [value]="itemMonth.value">
                                {{itemMonth.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text" id="basic-addon-day">
                            Día
                        </span>
                        <select formControlName="day"
                            class="form-select form-select-sm"
                            aria-describedby="basic-addon-day">
                            <option *ngFor="let itemDay of itemsDay" [value]="itemDay">
                                {{itemDay}}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" (click)="modalClose()">Aceptar</button>
        </div>
      </div>
    </div>
</div>