<!-- Modal De Fechas -->
<div #modalGeophone class="modal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
            Número telefónico
        </div>
        <div *ngIf="flatCode" class="input-group input-group-sm" style="padding: 1rem 2rem;">
            <span class="input-group-text" id="basic-addon-search">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="24" height="24"><path d="M3 10.982c0 3.845 3.137 6.982 6.982 6.982 1.518 0 3.036-.506 4.149-1.416L18.583 21 20 19.583l-4.452-4.452c.81-1.113 1.416-2.631 1.416-4.149 0-1.922-.81-3.643-2.023-4.958C13.726 4.81 11.905 4 9.982 4 6.137 4 3 7.137 3 10.982zM13.423 7.44a4.819 4.819 0 011.416 3.441c0 1.315-.506 2.53-1.416 3.44a4.819 4.819 0 01-3.44 1.417 4.819 4.819 0 01-3.441-1.417c-1.012-.81-1.518-2.023-1.518-3.339 0-1.315.506-2.53 1.416-3.44.911-1.012 2.227-1.518 3.542-1.518 1.316 0 2.53.506 3.44 1.416z" fill="currentColor"></path></svg>
            </span>
            <input [formControl]="search" type="text"
                class="form-control form-control-sm"
                placeholder="Buscar"
                aria-describedby="basic-addon-search">
        </div>
        <div class="modal-body">
            <div *ngIf="flatCode else elseBlock;">
                <div class="d-flex align-items-center btn py-3 border-bottom"
                    *ngFor="let pais of paises"
                    (click)="setcode(pais.code_phone)">
                    <div [innerHTML]="pais.svg" style="height: 75px; width: 75px;"></div>
                    <div class="ps-3 contenedor-puntillos">
                        <h4 class="text-start puntillos">{{pais.name}}</h4>
                        <h6 class="text-start m-0">{{pais.code_phone}}</h6>
                    </div>
                </div>
            </div>
            <ng-template #elseBlock>
                <form [formGroup]="formPhone" class="row">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text dropdown-toggle" id="basic-addon-number" (click)="flatCode=true">
                            {{code.value}}
                        </span>
                        <input formControlName="number" type="number"
                            class="form-control form-control-sm"
                            [class]="number.invalid && (number.dirty || number.touched) ? 'formValidatoError' : 
                            number.valid && (number.dirty || number.touched) ? 'formValidatoAccept' : ''"
                            placeholder="numero telefonico"
                            aria-describedby="basic-addon-number">
                    </div>
                </form>
            </ng-template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" (click)="modalClose()">Aceptar</button>
        </div>
      </div>
    </div>
</div>

<!--


    
-->