import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbar-home-lg',
  templateUrl: './toolbar-home-lg.component.html',
  styleUrls: ['./toolbar-home-lg.component.css']
})
export class ToolbarHomeLgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
  hoverItem(element:HTMLElement) {
    element.classList.add('bg-white');
    element.firstElementChild.classList.add('text-danger');
  }
  outItem(element:HTMLElement){
    element.classList.remove('bg-white');
    element.firstElementChild.classList.remove('text-danger');
  }
}
