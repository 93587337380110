import { AfterViewInit, Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { lista2 } from 'src/app/interfaces/lista-paises'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-popup-geophone',
  templateUrl: './popup-geophone.component.html',
  styleUrls: ['./popup-geophone.component.css']
})
export class PopupGeophoneComponent implements OnInit, AfterViewInit {

  myModal:any;
  flatCode:boolean = true;
  paises:Array<{name:string,svg:SafeHtml,code_phone:string}> = [];
  search:FormControl = new FormControl('');
  formPhone:FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required]),
  });

  @ViewChild('modalGeophone') element:ElementRef;
  @Output()
  closed = new EventEmitter();
  geophone = new EventEmitter();

  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.cargarPaises();
    this.search.valueChanges.subscribe((element)=>{
      this.cargarPaises(element);
    });
  }
  ngAfterViewInit(): void {
    this.myModal = new bootstrap.Modal(this.element.nativeElement);
    this.myModal.show();
  }
  modalClose(){
    this.formPhone.valid ? this.geophone.next(`${this.code.value + this.number.value}`) : '';
    this.myModal.hide();
    this.closed.next();
  }
  cargarPaises(element?:string){
    if (typeof element == 'undefined'){
      lista2.forEach((item)=>{
        this.paises.push({
          name: item.name,
          svg: this.sanitizer.bypassSecurityTrustHtml(item.svg),
          code_phone: item.code_phone
        })
      }); 
    } else if (typeof element !== 'undefined'){
      this.paises = [];
      lista2.forEach((item)=>{
        if (item.name.toLocaleLowerCase().includes(element.toLocaleLowerCase())) {
          this.paises.push({
            name: item.name,
            svg: this.sanitizer.bypassSecurityTrustHtml(item.svg),
            code_phone: item.code_phone
          });
        }
      });
    }
  }

  get code() { return this.formPhone.get('code') }
  setcode(element:string) {
    this.code.setValue(element);
    this.flatCode = false;
  }
  get number() { return this.formPhone.get('number') }
}
