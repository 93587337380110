<!-- Modal De Información -->
<div #myModal class="modal" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" [style.background-color]="configModal.bgColor">
      <div class="modal-header" #divModalTitle></div>
      <div #divModalBody class="modal-body"></div>
      <div class="modal-footer">
        <button type="button" class="btn text-white" [style.background-color]="configModal.bgColorBtn" (click)="modalClose()">{{configModal.textBtn}}</button>
      </div>
    </div>
  </div>
</div>