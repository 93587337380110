<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<div class="d-flex flex-column h-100" @myInsertRemoveTrigger [class]="flagWelcom?'d-none':''">
  <div class="fixed-top">
    <app-toolbar-home-lg></app-toolbar-home-lg>
  </div>
  
  <div [@triggerAnimarEnrutamiento]="recibirCambiosOutlet(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  
  <!-- Footer Home Lg -->
  <div class="mt-auto">
    <app-footer-home-lg></app-footer-home-lg>
  </div>
</div>
<div class="bg-white position-relative h-100" style="z-index: 1500;" [class]="flagWelcom?'':'d-none'">
  <div class="welcome-in"> </div>
</div>
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->